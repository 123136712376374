export default {
  data () {
    return {
      CREATED_AT: 'created_at',
      CREATED_BY: 'created_by',
      MODIFIED_AT: 'modified_at',
      MODIFIED_BY: 'modified_by',
      BEFORE: 'before',
      AFTER: 'after',
      BETWEEN: 'between',
      WITHIN_PAST: 'withinpast',
      WITHIN_NEXT: 'withinnext',
      THIS_WEEK: 'thisweek',
      THIS_MONTH: 'thismonth',
      LAST_WEEK: 'lastweek',
      LAST_MONTH: 'lastmonth',
      TODAY: 'today',
      SPECIFIC: 'specific',
      CONTAINS: 'contains',
      IS: 'is',
      IS_NOT: 'isnot',
      TRUE: 'true',
      FALSE: 'false'
    }
  }
}
